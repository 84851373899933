import { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';

import './contactForm.css'

export const ContactForm = () => {
  const [fields, setFields] = useState({
    name: '',
    typeOfContact: 'Phone Number',
    contactInfo: '',
    message: '',
  });

  const form = useRef();

  const sendContactInfo = (e) => {
    e.preventDefault();

    if (!fields.name) { alert('Please fill in a name!'); return; }
    if (!fields.message) { alert('Please include a message!'); return; }

    emailjs.sendForm('service_af8b8n8', 'template_8w87v5n', form.current, 'Wwqz3ESG_fQnGyQis')
        .then((result) => {
            alert('Success!' + result.toString())
        }, (error) => {
            alert('Our servers must be overloaded. We apologize for the inconvenience!' + error.toString())
        })
  }

  const placeholders = {
    'Phone Number': 'e.g., (555) 867-5309',
    'Email Address': 'e.g., brandonurie@funnyform.com',
    'Carrier Pidgeon': 'e.g., Doug'
  }

  return (
    <div className="contact-form__container">
      <form ref={form} className="contact-form" onSubmit={(e) => sendContactInfo(e)}>
        <span>Name</span>
        <input name="from_name" value={fields.name} onChange={(e) => setFields({ ...fields, name: e.target.value })} placeholder="e.g., Brandon Urie" />
        <span>Contact Info</span>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <select value={fields.typeOfContact} onChange={(e) => setFields({ ...fields, typeOfContact: e.target.value })}>
            <option name="phone-number">Phone Number</option>
            <option name="email-address">Email Address</option>
            <option name="carrier-pidgeon">Carrier Pidgeon</option>
          </select>
          <input name="from_info" value={fields.contactInfo} onChange={(e) => setFields({ ...fields, contactInfo: e.target.value })} className="no-border-radius" placeholder={placeholders[fields.typeOfContact]} />
        </div>
        <span>Message</span>
        <textarea name="message" value={fields.message} rows={7} onChange={(e) => setFields({ ...fields, message: e.target.value })} placeholder="Hey, I've put a lot of thought into it, and I want to make you the exclusive producer on my next album." />
        <button type="submit">Send Message!</button>
      </form>
    </div>
  )
}