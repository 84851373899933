import { useState, useEffect, useRef } from 'react';

import introPan from './introPanHoriz.mov';
import introPanMobile from './introPan.mp4';
import introPoster from './introposterHoriz.png';
import introPosterMobile from './introposter2.png';
import './App.css';
import { Helmet } from 'react-helmet';
import { ContactForm } from './ContactForm';

function App() {
  const [screenSize, setScreenSize] = useState({ width: window.innerWidth, height: window.innerHeight });

  const video = useRef();

  useEffect(() => {
    const updateDimension = () => {
      setScreenSize({ width: window.innerWidth, height: window.innerHeight });
    }
    window.addEventListener('resize', updateDimension);
    
    return(() => {
        window.removeEventListener('resize', updateDimension);
    })
  }, [screenSize]);

  useEffect(() => {
    if (!video.current) return;

    video.current.defaultMuted = true;
    video.current.muted = true;
  }, [video])

  return (
    <div className="App">
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Ubuntu&display=swap" rel="stylesheet" />
      </Helmet>
      <video ref={video} autoPlay loop playsInline muted className="bg-video" preload="auto" poster={screenSize.width < 768 ? introPosterMobile : introPoster}>
          {screenSize.width < 768 ? <source src={introPanMobile} type="video/mp4" /> :
            <source src={introPan} type="video/mp4" />}
        </video>
      <header className="App-header">
        <h1 style={{fontFamily: 'Ubuntu, serif', position: 'absolute', top: '8rem'}}>
          Timbrel and Lyre Productions
        </h1>
        <button className='cta' onClick={() => window.scroll({top: window.innerHeight, left: 0, behavior: 'smooth'})}>Get Studio Time!</button>
      </header>

      <ContactForm />
    </div>
  );
}

export default App;
